import React, { Component } from "react";
import Slider from "react-slick";

import { faQuoteLeft, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import people1 from "../../img/people/1.png";
import people2 from "../../img/people/2.png";
import people3 from "../../img/people/3.png";
import people4 from "../../img/people/4.png";
import people5 from "../../img/people/5.png";
import String from "../../string";
export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="quete">
        <div className="container">
          <h2>{String.quotetionTitle}</h2>
          <Slider {...settings}>
            <div>
              <FontAwesomeIcon className="icon" icon={faQuoteLeft} />
              <div className="qute-item">
                <div className="qute-item-people">
                  <img src={people1} alt="people1" />
                </div>
                <div className="qute-item-text">
                  <p>{String.firstQuotetionText}</p>
                  <p>
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                  </p>
                  <span>
                    by <b>{String.firstQuotetionBy}</b>
                  </span>
                </div>
              </div>
            </div>
            <div>
              <FontAwesomeIcon className="icon" icon={faQuoteLeft} />
              <div className="qute-item">
                <div className="qute-item-people">
                  <img src={people2} alt="people2" />
                </div>
                <div className="qute-item-text">
                  <p>{String.secondQuotetionText}</p>
                  <p>
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                  </p>
                  <span>
                    by <b>{String.secondQuotetionBy}</b>
                  </span>
                </div>
              </div>
            </div>
            <div>
              <FontAwesomeIcon className="icon" icon={faQuoteLeft} />
              <div className="qute-item">
                <div className="qute-item-people">
                  <img src={people3} alt="people3" />
                </div>
                <div className="qute-item-text">
                  <p>{String.thirdQuotetionText}</p>
                  <p>
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                  </p>
                  <span>
                    by <b>{String.thirdQuotetionBy}</b>
                  </span>
                </div>
              </div>
            </div>
            <div>
              <FontAwesomeIcon className="icon" icon={faQuoteLeft} />
              <div className="qute-item">
                <div className="qute-item-people">
                  <img src={people4} alt="people4" />
                </div>
                <div className="qute-item-text">
                  <p>{String.forthQuotetionText}</p>
                  <p>
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                  </p>
                  <span>
                    by <b>{String.forthQuotetionBy}</b>
                  </span>
                </div>
              </div>
            </div>
            <div>
              <FontAwesomeIcon className="icon" icon={faQuoteLeft} />
              <div className="qute-item">
                <div className="qute-item-people">
                  <img src={people5} alt="people5" />
                </div>
                <div className="qute-item-text">
                  <p>{String.fifthQuotetionText}</p>
                  <p>
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                    <FontAwesomeIcon className="rting-icon" icon={faStar} />
                  </p>
                  <span>
                    by <b>{String.fifthQuotetionBy}</b>
                  </span>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}
