import React from 'react'
import Blog from 'dugoly-blog'


const blogPage=()=>{

return(
    <Blog ownerUid="-Ma34p80RkOXfPHK5Bca" bCode="-Ma37SXBROLmTt4BbZPm" BlogTitle="טיולי מעבר לאופק - הבלוג!"  dir="rtl"/>
    
)

}

export default blogPage