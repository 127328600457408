import React from "react";
import aboutImg from "../../img/about.jpg";
import String from "../../string";
import bg2 from "../../img/bg2.png"

const About = () => {
  return (
    <section className="about-us" style={{backgroundImage: `url(${bg2})`}} >
      <div className="container">
        <div className="about-us-text" dir="rtl">
          <h2>{String.aboutTitle}</h2>
          <p>{String.aboutTextOne}</p>
          <p>{String.aboutTextTwo}</p>
        </div>
        <div className="about-us-img">
          <img src={aboutImg} alt="about" />
        </div>
      </div>
    </section>
  );
};

export default About;
