import React from "react";
import String from "../../string";
const Footer = () => {
  return (
    <footer>
      <div className="container">
        <p ><a href="https://motto.co.il/" style={{color:"#A8DDFE"}}>{String.craditText}</a></p>
        <p>{String.copyrightText}</p>
      </div>
    </footer>
  );
};

export default Footer;
