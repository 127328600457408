const String = {
  heroText: "טיולי מעבר לאופק",
  infoItemOne: "יעוץ והמלצות",
  infoItemTwo: "הדרכת טיולים",
  infoItemThree: "בתי מלון ופינוקים",
  brandingTitle: "השותפים שלנו",
  gridIemOneTitle: "Title Goes here?",
  gridIemOneText:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  gridIemTwoTitle: "Title Goes here?",
  gridIemTwoText:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  gridIemThreeTitle: "Title Goes here?",
  gridIemThreeText:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  gridIemFourTitle: "Title Goes here?",
  gridIemFourText:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  quotetionTitle: "Whay People Say About US?",
  firstQuotetionText:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  firstQuotetionBy: "MR. x",
  secondQuotetionText:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  secondQuotetionBy: "MR. y",
  thirdQuotetionText:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  thirdQuotetionBy: "MR. y",
  forthQuotetionText:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  forthQuotetionBy: "MR. y",
  fifthQuotetionText:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  fifthQuotetionBy: "MR. y",
  newsletterTitle: "ubscribe to our Newsletter",
  newsletterText:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  serviceTitle: "השרותים שאנו מספקים",
  serviceItemOne: "שרות מעולה",
  serviceItemTwo: "חדרים מפנקים",
  serviceItemThree: "אוכל מעורר תאבון",
  serviceItemFour: "יחס אישי",
  aboutTitle: "קצת עלינו",
  aboutTextOne: `Contrary to popular belief, Lorem Ipsum is not simply random text.
  It has roots in a piece of classical Latin literature from 45 BC,
  making it over 2000 years old`,
  aboutTextTwo: `Contrary to popular belief, Lorem Ipsum is not simply random text.
  It has roots in a piece of classical Latin literature from 45 BC,
  making it over 2000 years old`,

  craditText: "Created by motto, Application and website development",
  copyrightText: "© Copyright 2021",
};

export default String;
