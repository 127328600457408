import BrandShowCase from "./component/brandsShowCase/BrandShowCase";
import Footer from "./component/footer/Footer";
import Grid from "./component/grid/Grid";
import Header from "./component/header/Header";
import Hero from "./component/hero/Hero";
import Info from "./component/info/Info";
import LeadForm from "./component/ledForm/Led-form";
import SimpleSlider from "./component/quete/Quete";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import About from "./component/about/About";
import Service from "./component/service/Service";
import Blog from './component/blog'
function App() {
  return (
    <Router>
      <div>
        <Header />
        <Switch>
          <Route
            path="/"
            exact
            render={() => (
              <div>
                <Hero />
                <Info />
                <BrandShowCase />
                <Grid />
                <SimpleSlider />
                <LeadForm />
              </div>
            )}
          />
          <Route path="/about" component={About} />
          <Route path="/service" component={Service} />
          <Route path="/blog" component={Blog} />

        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
