import React from "react";
import img1 from "../../img/grid/1.jpg";
import img2 from "../../img/grid/2.jpg";
import img3 from "../../img/grid/3.jpg";
import img4 from "../../img/grid/4.jpg";
import String from "../../string";
const Grid = () => {
  return (
    <section className="grid">
      <div className="container">
        <div className="grid-item">
          <img src={img1} alt="grid-img" />
          <h3>{String.gridIemOneTitle}</h3>
          <p>{String.gridIemOneText}</p>
        </div>
        <div className="grid-item">
          <img src={img2} alt="grid-img" />
          <h3>{String.gridIemTwoTitle}</h3>
          <p>{String.gridIemTwoText}</p>
        </div>
        <div className="grid-item">
          <img src={img3} alt="grid-img" />
          <h3>{String.gridIemThreeTitle}</h3>
          <p>{String.gridIemThreeText}</p>
        </div>
        <div className="grid-item">
          <img src={img4} alt="grid-img" />
          <h3>{String.gridIemFourTitle}</h3>
          <p>{String.gridIemFourText}</p>
        </div>
      </div>
    </section>
  );
};

export default Grid;
