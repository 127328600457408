import React from "react";
import { faComment, faPlane, faHotel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import String from "../../string";

const Info = () => {
  return (
    <section className="info">
      <div className="container">
        <div className="info-item">
          <FontAwesomeIcon icon={faComment} />
          <h3>{String.infoItemOne}</h3>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon={faPlane} />
          <h3>{String.infoItemTwo}</h3>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon={faHotel} />
          <h3>{String.infoItemThree}</h3>
        </div>
      </div>
    </section>
  );
};

export default Info;
