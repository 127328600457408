import React, { Component } from "react";
import Slider from "react-slick";
import Brand1 from "../../img/brands/1.png";
import Brand2 from "../../img/brands/2.png";
import Brand3 from "../../img/brands/3.png";
import Brand4 from "../../img/brands/4.png";
import Brand5 from "../../img/brands/5.png";
import Brand6 from "../../img/brands/6.png";
import Brand7 from "../../img/brands/7.png";
import Brand8 from "../../img/brands/8.png";
import Brand9 from "../../img/brands/9.png";
import String from "../../string";
export default class BrandShowCase extends Component {
  render() {
    const settings = {
      dots: false,
      autoplay: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
    };
    return (
      <div className="brand-item">
        <div className="container">
          <h2>{String.brandingTitle}</h2>
          <Slider {...settings}>
            <div>
              <img className="brand-logo" src={Brand1} alt="Brand" />
            </div>
            <div>
              <img className="brand-logo" src={Brand2} alt="Brand" />
            </div>
            <div>
              <img className="brand-logo" src={Brand3} alt="Brand" />
            </div>
            <div>
              <img className="brand-logo" src={Brand4} alt="Brand" />
            </div>
            <div>
              <img className="brand-logo" src={Brand5} alt="Brand" />
            </div>
            <div>
              <img className="brand-logo" src={Brand6} alt="Brand" />
            </div>
            <div>
              <img className="brand-logo" src={Brand7} alt="Brand" />
            </div>
            <div>
              <img className="brand-logo" src={Brand8} alt="Brand" />
            </div>
            <div>
              <img className="brand-logo" src={Brand9} alt="Brand" />
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}
