import React from "react";
import String from "../../string";
const Hero = () => {
  return (
    <section className="hero">
      <h1>{String.heroText}</h1>
    </section>
  );
};

export default Hero;
