import React from "react";
import img1 from "../../img/service/1.jpg";
import img2 from "../../img/service/2.jpg";
import img3 from "../../img/service/3.jpg";
import img4 from "../../img/service/4.jpg";
import String from "../../string";
import bg3 from "../../img/bg3.png"


const Service = () => {
  return (
    <section className="service" style={{backgroundImage: `url(${bg3})`}}>
      <div className="service-header">
        <h2>{String.serviceTitle}</h2>
      </div>
      <div className="container">
        <div className="service-items">
          <img src={img1} alt="img" />
          <h3>{String.serviceItemOne}</h3>
        </div>
        <div className="service-items">
          <h3>{String.serviceItemTwo}</h3>
          <img src={img2} alt="img" />
        </div>
        <div className="service-items">
          <img src={img3} alt="img" />
          <h3>{String.serviceItemThree}</h3>
        </div>
        <div className="service-items">
          <h3>{String.serviceItemFour}</h3>
          <img src={img4} alt="img" />
        </div>
      </div>
    </section>
  );
};

export default Service;
