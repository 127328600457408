import React from "react";
import String from "../../string";
const LeadForm = () => {
  return (
    <section className="lead-box">
      <form action="">
        <div class="container">
          <h2>{String.newsletterTitle}</h2>
          <p>{String.newsletterText}</p>
        </div>

        <div class="container">
          <input type="text" placeholder="Name" name="name" required />
          <input type="text" placeholder="Email address" name="mail" required />
          <input type="submit" value="Subscribe" />
        </div>
      </form>
    </section>
  );
};

export default LeadForm;
